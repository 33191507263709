// react imports
import React, { useContext, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import menu from '../../assets/media/images/atalakitoprogram/etrend.jpeg'
import etrend from '../../assets/media/images/atalakitoprogram/etrend sajat.jpeg'
import edzes from '../../assets/media/images/atalakitoprogram/edzes.jpeg'
import kegeszitok from '../../assets/media/images/atalakitoprogram/kiegeszito.jpeg'

import menu2 from '../../assets/media/images/atalakitoprogram/etrend2.jpeg'
import etrend2 from '../../assets/media/images/atalakitoprogram/etrend sajat 2.jpeg'
import edzes2 from '../../assets/media/images/atalakitoprogram/edzes2.png'
import kegeszitok2 from '../../assets/media/images/atalakitoprogram/kiegeszito2.jpeg'

import menu3 from '../../assets/media/images/atalakitoprogram/etrend3.jpeg'
import etrend3 from '../../assets/media/images/atalakitoprogram/etrend sajat 3.jpeg'
import edzes3 from '../../assets/media/images/atalakitoprogram/edzes3.jpeg'
import kegeszitok3 from '../../assets/media/images/atalakitoprogram/kiegeszito3.jpeg'

// CUSTOM COMPONENTS
import AppContext from '../../AppContext';
import Cookies from 'universal-cookie';
import { MDBContainer } from "mdb-react-ui-kit";
const cookies = new Cookies();

const VillamAtalakitoprogramPage = (props) => {


  // REACT ROUTER PARAMS - for processing url
  const [searchParams] = useSearchParams();
  const context = useContext(AppContext);
  

  // STATES
  const [isCard, setIsCard] = useState(true);
  
  
  return (
    <div>
        <section className={context.getState().liveTime !== undefined  ? "atalakitoprogram-section live" : "atalakitoprogram-section"}>
            <MDBContainer fluid style={{maxWidth: "1170px"}}>
                <div className="title">
                    <p>Életmódváltó program</p>
                </div>
                <div className="menu pb-5">
                    <span>1. heti edzésterv</span>
                    <img src={edzes} alt="elso_het_menu"/>
                </div>
               
                <div className="menu pb-2">
                    <span>1. heti étrend</span>
                    <img src={menu} alt="elso_het_menu"/>
                </div>
                <div className="edzesterv pb-5">
                    <span>Ha nem tudsz rendelni...</span>
                    <img src={etrend} alt="elso_het_menu"/>
                </div>
                <div className="edzesterv pb-5">
                    <img src={kegeszitok} alt="elso_het_menu"/>
                </div>
                <div className="menu pb-5">
                    <span>2. heti edzésterv</span>
                    <img src={edzes2} alt="elso_het_menu"/>
                </div>
               
                <div className="menu pb-2">
                    <span>2. heti étrend</span>
                    <img src={menu2} alt="elso_het_menu"/>
                </div>
                <div className="edzesterv pb-5">
                    <span>Ha nem tudsz rendelni...</span>
                    <img src={etrend2} alt="elso_het_menu"/>
                </div>
                <div className="edzesterv pb-5">
                    <img src={kegeszitok2} alt="elso_het_menu"/>
                </div>
                <div className="menu pb-5">
                    <span>3. heti edzésterv</span>
                    <img src={edzes3} alt="elso_het_menu"/>
                </div>
               
                <div className="menu pb-2">
                    <span>3. heti étrend</span>
                    <img src={menu3} alt="elso_het_menu"/>
                </div>
                <div className="edzesterv pb-5">
                    <span>Ha nem tudsz rendelni...</span>
                    <img src={etrend3} alt="elso_het_menu"/>
                </div>
                <div className="edzesterv">
                    <img src={kegeszitok3} alt="elso_het_menu"/>
                </div>
               
            </MDBContainer>
        
        </section>
   
        
       
        
    </div>
  );
};

export default VillamAtalakitoprogramPage;
